import React, {
  useEffect,
  useState,
  MouseEvent,
  SetStateAction,
  Dispatch
} from "react";
import { TextField, Grid, FormLabel } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  contractFormSetContract,
  contractFormSendContract,
  contractFormRetrieveTogglProjects,
  contractFormRetrieveTogglWorkspaces,
  projectsRetrieveProjects
} from "redux/actions";
import { ContractFormContract } from "../../types/contract";
import { ContractFormErrors } from "../../redux/types/contractForm";
import { DateRangePicker } from "views/Contract/DateRangePicker";
import ProjectDropDown from "./ProjectsDropDown";
import DevelopersDropDown from "./DevelopersDropDown";
import ContractStatusPicker from "./ContractStatusPicker";
import ProviderDropDown from "./ProviderDropDown";
import { CurrencyDropDown } from "./CurrencyDropDown";
import { ContractTypeDropDown } from "./ContractTypeDropDown";
import SubmitButton from "./SubmitButton";
import PositionDropDown from "./PositionDropDown";

interface ContractFormProps {
  contractId: number;
  setSendContractAttempt: Dispatch<SetStateAction<boolean>>;
}

export default function ContractForm({
  contractId,
  setSendContractAttempt
}: ContractFormProps) {
  const dispatch = useDispatch();

  const [isBeingEdited, setIsBeingEdited] = useState<boolean>(contractId === 0);

  const contract: ContractFormContract = useSelector(
    // @ts-ignore
    state => state.contractForm.data
  );
  const sendErrors: ContractFormErrors | null = useSelector(
    // @ts-ignore
    state => state.contractForm.sendErrors
  );

  const formErrors = [];
  let fieldsErrors: ContractFormErrors = {};
  if (sendErrors !== null) {
    if (Array.isArray(sendErrors)) {
      for (const error of sendErrors) {
        formErrors.push(<FormLabel error={true}>{error}</FormLabel>);
      }
    } else {
      formErrors.push(
        <FormLabel error={true}>Please fix all errors</FormLabel>
      );
      fieldsErrors = sendErrors;
    }
  }

  /* Update the list of Toggl workspaces on developer change */
  useEffect(() => {
    if (contract.developer_id) {
      dispatch(contractFormRetrieveTogglWorkspaces(contract.developer_id));
    }
  }, [dispatch, contract.developer_id]);

  /* Update the list of Toggl projects when the selected workspace changes */
  useEffect(() => {
    if (contract.developer_id && contract.toggl_workspace_id) {
      dispatch(
        contractFormRetrieveTogglProjects(
          contract.developer_id,
          contract.toggl_workspace_id
        )
      );
    }
  }, [dispatch, contract.developer_id, contract.toggl_workspace_id]);

  useEffect(() => {
    dispatch(projectsRetrieveProjects());
  }, [dispatch]);

  const sendForm = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setSendContractAttempt(true);

    dispatch(contractFormSendContract(contract));
  };

  return (
    <form>
      <Grid container spacing={2}>
        {formErrors.length > 0 && (
          <Grid item xs={12}>
            {formErrors}
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            id="name"
            name="name"
            disabled={!isBeingEdited}
            fullWidth
            label="Name"
            required
            value={contract.name}
            onChange={event => {
              dispatch(
                contractFormSetContract({
                  ...contract,
                  name: event.target.value
                })
              );
            }}
            error={fieldsErrors.name !== undefined}
            helperText={
              fieldsErrors.name !== undefined ? fieldsErrors.name : ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <DevelopersDropDown
            contract={contract}
            isBeingEdited={isBeingEdited}
            fieldsErrors={fieldsErrors}
          />
        </Grid>
        <Grid item xs={12}>
          <ProjectDropDown
            contract={contract}
            isBeingEdited={isBeingEdited}
            fieldsErrors={fieldsErrors}
          />
        </Grid>
        <Grid item xs={12}>
          <PositionDropDown
            contract={contract}
            isBeingEdited={isBeingEdited}
            fieldsErrors={fieldsErrors}
          />
        </Grid>
        <Grid item xs={12}>
          <DateRangePicker
            contract={contract}
            isBeingEdited={isBeingEdited}
            fieldsErrors={fieldsErrors}
          />
        </Grid>
        <Grid item xs={12}>
          <ContractStatusPicker
            contract={contract}
            isBeingEdited={isBeingEdited}
            fieldsErrors={fieldsErrors}
          />
        </Grid>
        <Grid item xs={12}>
          <ContractTypeDropDown
            contract={contract}
            isBeingEdited={isBeingEdited}
            fieldsErrors={fieldsErrors}
          />
        </Grid>
        <Grid item xs={12}>
          <CurrencyDropDown
            contract={contract}
            isBeingEdited={isBeingEdited}
            fieldsErrors={fieldsErrors}
          />
        </Grid>
        {contract.type === "fixed" && (
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              disabled={!isBeingEdited}
              id="amount"
              label="Contract Amount $"
              margin="normal"
              value={contract.contract_amount}
              onChange={event => {
                dispatch(
                  contractFormSetContract({
                    ...contract,
                    contract_amount: event.target.value
                  })
                );
              }}
              error={fieldsErrors.contract_amount !== undefined}
              helperText={fieldsErrors.contract_amount || ""}
            />
          </Grid>
        )}
        {contract.type === "subscription" && (
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              disabled={!isBeingEdited}
              label="Daily Workload (hours)"
              value={contract.daily_workload}
              onChange={event => {
                dispatch(
                  contractFormSetContract({
                    ...contract,
                    daily_workload: event.target.value
                  })
                );
              }}
              error={fieldsErrors.daily_workload !== undefined}
              helperText={fieldsErrors.daily_workload || ""}
            />
          </Grid>
        )}
        {contract.type !== "fixed" && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={!isBeingEdited}
              label="Hourly Rate"
              value={contract.hourly_rate}
              onChange={event => {
                dispatch(
                  contractFormSetContract({
                    ...contract,
                    hourly_rate: event.target.value
                  })
                );
              }}
              error={fieldsErrors.hourly_rate !== undefined}
              helperText={fieldsErrors.hourly_rate || ""}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ProviderDropDown
            contract={contract}
            isBeingEdited={isBeingEdited}
            fieldsErrors={fieldsErrors}
          />
        </Grid>
        <Grid item xs={12}>
          <SubmitButton
            isBeingEdited={isBeingEdited}
            setIsBeingEdited={setIsBeingEdited}
            contractId={contractId}
            sendForm={sendForm}
          />
        </Grid>
      </Grid>
    </form>
  );
}
