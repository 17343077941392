import { takeLatest, call, all, put, select } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import { RETRIEVE_CURRENT_USER } from "./types/currentUser";
import { RETRIEVE_ACCOUNT_MANAGERS } from "./types/accountManagers";
import { CONTRACT_REPORTS_RETRIEVE_CONTRACT_REPORTS } from "./types/contractReports";
import {
  CONTRACT_FORM_RETRIEVE_CONTRACT,
  CONTRACT_FORM_RETRIEVE_TOGGL_PROJECTS,
  CONTRACT_FORM_RETRIEVE_TOGGL_WORKSPACES,
  CONTRACT_FORM_SEND_CONTRACT
} from "redux/types/contractForm";
import { GENERAL_RETRIEVE_CLIENT_NAMES } from "redux/types/general";
import * as actions from "./actions";
import dataFetcher from "services/dataFetcher";
import {
  PROJECT_STATUSES,
  CONTRACT_STATUSES,
  CONTRACT_TYPES,
  DATE_FORMAT
} from "../variables/general";
import moment from "moment";
import { contractFormSetContract, projectFormSetProject } from "./actions";
import { contractFormSetTogglWorkspaces } from "./actions";
import { contractFormSetNoTogglKey } from "./actions";
import {
  PROJECT_FORM_RETRIEVE_PROJECT,
  PROJECT_FORM_SEND_PROJECT
} from "./types/projectForm";
import { PROJECT_GROUPS_RETRIEVE_GROUPS } from "./types/projectGroups";

/**
 * Transform response body of contract to contract state object
 * @param contractBody
 */
function makeContractState(contractBody = {}) {
  let contract = { ...contractBody };
  if (contract.developer) {
    contract.developer_id = contract.developer.uid;
  }

  if (contract.account_manager) {
    contract.account_manager_id = contract.account_manager.uid;
  }

  if (contract.project) {
    contract.project_id = contract.project.id;
  }

  if (contract.provider === "toggl" && contract.toggl) {
    contract.toggl_workspace_id = contract.toggl.workspace_id;
    contract.toggl_project_id = contract.toggl.project_id;
  }

  delete contract.developer;
  delete contract.account_manager;
  delete contract.project;
  delete contract.toggl;
  return contract;
}

/**
 * Transform response body of project to project state object
 * @param projectBody
 */
function makeProjectState(projectBody = {}) {
  let project = { ...projectBody };

  if (project.account_manager) {
    project.account_manager_id = project.account_manager.uid;
  }

  project.client = project.client || "";

  if (project.project_group) {
    project.project_group_id = project.project_group.id;
  }

  delete project.account_manager;
  delete project.project_group;
  return project;
}

function* watchRetrieveContracts() {
  yield takeLatest(actionTypes.CONTRACTS_RETRIEVE_CONTRACTS, retrieveContracts);
}

function* retrieveContracts(action) {
  const params = yield select(state => state.contracts.params);
  const contractStatusesAvailable = CONTRACT_STATUSES.map(
    contractStatus => contractStatus.id
  );

  const queryParams = {
    status: params.contractStatus,
    account_manager_id: params.accountManagerId
  };

  if (params.accountManagerId === 0) {
    delete queryParams.account_manager_id;
  }

  if (!contractStatusesAvailable.includes(queryParams.status)) {
    delete queryParams.status;
  }
  const result = yield call(dataFetcher.contracts.getContracts, queryParams);
  if (result.data.status === "success") {
    const contracts = result.data.data.map(value => {
      const contractType = CONTRACT_TYPES.find(
        contractType => contractType.id === value.type
      );
      const contractStatus = CONTRACT_STATUSES.find(
        contractStatus => contractStatus.id === value.status
      );
      return {
        id: value.id,
        name: value.name,
        client: (value.project && value.project.client) || "",
        project_id: value.project ? value.project.id : null,
        hourly_rate: value.hourly_rate,
        status: contractStatus !== undefined ? contractStatus.label : "",
        type: contractType !== undefined ? contractType.label : "",
        developer: value.developer.name,
        time_intervals: value.time_intervals || [],
        account_manager_id:
          (value.project &&
            value.project.account_manager &&
            value.project.account_manager.uid) ||
          ""
      };
    });

    yield put(actions.contractsSetContracts(contracts));
  } else {
    // @TODO we need to implement error handling.
  }
}

function* watchRetrieveProjects() {
  yield takeLatest(actionTypes.PROJECTS_RETRIEVE_PROJECTS, retrieveProjects);
}

function* retrieveProjects(action) {
  const params = yield select(state => state.projects.params);
  const projectStatusesAvailable = PROJECT_STATUSES.map(
    projectStatus => projectStatus.id
  );

  const queryParams = {
    status: params.projectStatus,
    account_manager_id: params.accountManagerId
  };

  if (params.accountManagerId === 0) {
    delete queryParams.account_manager_id;
  }

  if (!projectStatusesAvailable.includes(queryParams.status)) {
    delete queryParams.status;
  }
  const result = yield call(dataFetcher.projects.getProjects, queryParams);
  if (result.data.status === "success") {
    const projects = result.data.data.map(value => {
      const projectStatus = PROJECT_STATUSES.find(
        projectStatus => projectStatus.id === value.status
      );
      return {
        id: value.id,
        name: value.name,
        client: value.client,
        status: (projectStatus && projectStatus.label) || "",
        account_manager_id:
          (value.account_manager && value.account_manager.uid) || null
      };
    });
    yield put(actions.projectsSetProjects(projects));
  } else {
    // @TODO we need to implement error handling.
  }
}

function* watchRetrieveProjectGroups() {
  yield takeLatest(PROJECT_GROUPS_RETRIEVE_GROUPS, retrieveProjectGroups);
}

function* retrieveProjectGroups() {
  const result = yield call(dataFetcher.projectGroups.getProjectGroups);
  if (result.data.status === "success") {
    yield put(actions.projectGroupsSetGroups(result.data.data));
  }
}

function* watchRetrieveCurrentUser() {
  yield takeLatest(RETRIEVE_CURRENT_USER, retrieveCurrentUser);
}

function* retrieveCurrentUser(action) {
  const result = yield call(dataFetcher.user.get);
  if (result.data.status === "success") {
    yield put(actions.setCurrentUser(result.data.data));
  } else {
    // @TODO we need error handling here
  }
}

function* watchRetrieveInactiveContracts() {
  yield takeLatest(
    actionTypes.CONTRACTS_RETRIEVE_INACTIVE_CONTRACTS,
    retrieveInactiveContracts
  );
}

function* retrieveInactiveContracts(action) {
  const params = yield select(state => state.currentUser);

  if (params.user.uid) {
    const queryParams = {
      account_manager_id: params.user.uid
    };
    const result = yield call(
      dataFetcher.contracts.getInactiveContracts,
      queryParams
    );
    if (result.data.status === "success") {
      const inactive = result.data.data.map(value => {
        const contractType = CONTRACT_TYPES.find(
          contractType => contractType.id === value.type
        );
        const contractStatus = CONTRACT_STATUSES.find(
          contractStatus => contractStatus.id === value.status
        );
        return {
          id: value.id,
          name: value.name,
          client: value.client,
          hourly_rate: value.hourly_rate,
          status: contractStatus !== undefined ? contractStatus.label : "",
          type: contractType !== undefined ? contractType.label : "",
          developer: value.developer.name,
          time_intervals: value.time_intervals || [],
          account_manager_id:
            (value.project && value.project.account_manager_id) || ""
        };
      });
      yield put(actions.contractsSetInactiveContracts(inactive));
    } else {
      // @TODO we need error handling here
    }
  }
}

function* watchRetrieveDevelopers() {
  yield takeLatest(actionTypes.RETRIEVE_DEVELOPERS, retrieveDevelopers);
}

function* retrieveDevelopers() {
  const result = yield call(dataFetcher.developers.getActiveDevelopers);
  if (result.data.status === "success") {
    const developers = result.data.data;
    yield put(actions.setDevelopers(developers));
  }
}

function* watchContractFormRetrieveContract() {
  yield takeLatest(
    CONTRACT_FORM_RETRIEVE_CONTRACT,
    contractFormRetrieveContract
  );
}

function* contractFormRetrieveContract(action) {
  const result = yield call(dataFetcher.contracts.getContract, [
    action.payload
  ]);
  if (result.data.status === "success") {
    yield put(
      actions.contractFormSetContract(makeContractState(result.data.data))
    );
  } else if (result.data.status === "error") {
    let errors = ["Unrecognized error"];
    if (result.data.data.errors !== undefined) {
      errors = result.data.data.errors;
    }
    yield put(actions.contractFormSetRetrieveContractErrors(errors));
  } else {
    throw new Error(
      "Unrecognized response from server on retrieve contract attempt."
    );
  }
}

function* watchProjectFormRetrieveProject() {
  yield takeLatest(PROJECT_FORM_RETRIEVE_PROJECT, projectFormRetrieveProject);
}

function* projectFormRetrieveProject(action) {
  const result = yield call(dataFetcher.projects.getProject, [action.payload]);
  if (result.data.status === "success") {
    yield put(
      actions.projectFormSetProject(makeProjectState(result.data.data))
    );
  } else if (result.data.status === "error") {
    yield put(
      actions.projectFormSetRetrieveProjectErrors(
        result.data.data.errors || ["Unrecognized error"]
      )
    );
  } else {
    throw new Error(
      "Unrecognized response from server on retrieve project attempt."
    );
  }
}

function* watchContractFormSendContract() {
  yield takeLatest(CONTRACT_FORM_SEND_CONTRACT, contractFormSendContract);
}

function* contractFormSendContract(action) {
  let contract = { ...action.payload };
  const contractId = contract.id;
  delete contract.id;
  if (contract.provider !== "toggl") {
    delete contract.toggl_workspace_id;
    delete contract.toggl_project_id;
  }
  let result;
  if (contractId === 0) {
    result = yield call(dataFetcher.contracts.createContract, contract);
  } else {
    result = yield call(
      dataFetcher.contracts.updateContract,
      contractId,
      contract
    );
  }
  if (result.data.status === "success") {
    yield put(contractFormSetContract(makeContractState(result.data.data)));
  } else if (result.data.status === "error") {
    let errors = ["Unrecognized error"];
    if (result.data.data.errors !== undefined) {
      errors = result.data.data.errors;
    }
    yield put(actions.contractFormSetSendContractErrors(errors));
  } else {
    throw new Error(
      "Unrecognized response from server on send contract attempt."
    );
  }
}

function* watchProjectFormSendProject() {
  yield takeLatest(PROJECT_FORM_SEND_PROJECT, projectFormSendProject);
}

function* projectFormSendProject(action) {
  let project = { ...action.payload };
  const projectId = project.id;
  delete project.id;
  let result;
  if (projectId === 0) {
    result = yield call(dataFetcher.projects.createProject, project);
  } else {
    result = yield call(dataFetcher.projects.updateProject, projectId, project);
  }
  if (result.data.status === "success") {
    yield put(projectFormSetProject(makeProjectState(result.data.data)));
  } else if (result.data.status === "error") {
    yield put(
      actions.projectFormSetSendProjectErrors(
        result.data.data.errors || ["Unrecognized error"]
      )
    );
  } else {
    throw new Error(
      "Unrecognized response from server on send contract attempt."
    );
  }
}

function* watchRetrieveTimeReports() {
  yield takeLatest(
    actionTypes.TIME_REPORTS_RETRIEVE_TIME_REPORTS,
    retrieveTimeReports
  );
}

function* retrieveTimeReports() {
  const params = yield select(state => state.timeReports.params);
  const filters = yield select(state => state.timeReports.filters);
  const pager = yield select(state => state.timeReports.pager);
  const sorts = yield select(state => state.timeReports.sorting);
  const requestParams = {
    start_date: params.startDate,
    end_date: params.endDate,
    page: pager.currentPage,
    per_page: pager.perPage
  };
  for (let i = 0; i < filters.length; i++) {
    requestParams[filters[i].columnName] = filters[i].value;
  }
  if (params.accountManagerId !== 0) {
    requestParams.account_manager_id = params.accountManagerId;
  }
  const sortsParams = [];
  for (let i = 0; i < sorts.length; i++) {
    sortsParams.push(sorts[i].columnName + ":" + sorts[i].direction);
  }
  if (sortsParams.length > 0) {
    requestParams.sort_by = sortsParams.join(",");
  }
  const result = yield call(dataFetcher.timeReports.getReports, requestParams);
  if (result.data.status === "success") {
    const timeReports = result.data.data;
    yield put(actions.timeReportsSetTimeReports(timeReports));
    yield put(
      actions.dailyTimeReportsSetPager({
        total: result.data.meta.total,
        perPage: result.data.meta.per_page,
        currentPage: result.data.meta.current_page
      })
    );
  } else {
    // @TODO we need to implement error handling.
  }
}

function* watchRetrieveDailyTimeReports() {
  yield takeLatest(
    actionTypes.DAILY_TIME_REPORTS_RETRIEVE_TIME_REPORTS,
    retrieveDailyTimeReports
  );
}

function* retrieveDailyTimeReports() {
  const params = yield select(state => state.dailyTimeReports.params);
  const pager = yield select(state => state.dailyTimeReports.pager);
  const requestParams = {
    start_date: params.startDate,
    end_date: params.endDate,
    page: pager.currentPage,
    days_per_page: pager.daysPerPage
  };
  if (params.accountManagerId !== 0) {
    requestParams.account_manager_id = params.accountManagerId;
  }
  const result = yield call(
    dataFetcher.timeReports.getDailyReports,
    requestParams
  );
  if (result.data.status === "success") {
    const timeReports = yield call(extendDailyTimeReports, result.data.data);
    yield put(actions.dailyTimeReportsSetTimeReports(timeReports));
    yield put(
      actions.dailyTimeReportsSetPager({
        daysTotal: result.data.meta.days_total,
        currentPage: result.data.meta.current_page,
        daysPerPage: result.data.meta.days_per_page
      })
    );
  } else {
    // @TODO we need to implement error handling.
  }
}

/**
 * Fill time reports array with records for developers having zero hours on particular date.
 * @param timeReports
 * @returns {Generator<any, *, ?>}
 */
function* extendDailyTimeReports(timeReports) {
  let contracts = yield select(state => state.contracts.list);
  if (contracts.length === 0) {
    yield call(retrieveContracts);
  }
  contracts = yield select(state => state.contracts.list);
  const accountManagerIdParam = yield select(
    state => state.dailyTimeReports.params.accountManagerId
  );

  contracts = contracts.filter(contract => {
    return contract.status === "Active";
  });

  if (accountManagerIdParam !== 0) {
    contracts = contracts.filter(contract => {
      return contract.account_manager_id === accountManagerIdParam;
    });
  }

  const timeReportsDeveloperIdsByDate = {};

  for (const timeReport of timeReports) {
    if (timeReportsDeveloperIdsByDate[timeReport.day_worked_on] === undefined) {
      timeReportsDeveloperIdsByDate[timeReport.day_worked_on] = {};
    }
    if (
      timeReportsDeveloperIdsByDate[timeReport.day_worked_on][
        timeReport.contract.developer.name
      ] === undefined
    ) {
      timeReportsDeveloperIdsByDate[timeReport.day_worked_on][
        timeReport.contract.developer.name
      ] = 0;
    }
  }
  for (const date of Object.keys(timeReportsDeveloperIdsByDate)) {
    for (const contract of contracts) {
      if (!(contract.developer in timeReportsDeveloperIdsByDate[date])) {
        timeReports.push({
          contract: {
            ...contract,
            developer: { name: contract.developer },
            account_manager: { uid: contract.account_manager_id }
          },
          day_worked_on: date,
          hours: 0
        });
      }
    }
  }
  return timeReports;
}

function* watchSendTimeReport() {
  yield takeLatest(
    actionTypes.TIME_REPORT_FORM_SEND_TIME_REPORT,
    sendTimeReport
  );
}

function* sendTimeReport(action) {
  let timeReport = yield select(state => state.timeReportForm.timeReport);
  let contract = yield select(state => state.timeReportForm.contract);
  timeReport = { ...timeReport };
  timeReport.contract_id = contract.id;
  const timeReportId = timeReport.id;
  delete timeReport.id;
  let result;
  if (timeReportId === 0) {
    if (timeReport.minutes !== "") {
      timeReport.hours = (
        Number(timeReport.hours) + Number((timeReport.minutes / 60).toFixed(2))
      ).toString();
    }
    delete timeReport.minutes;
    result = yield call(dataFetcher.timeReports.createTimeReport, timeReport);
  } else {
    result = yield call(
      dataFetcher.timeReports.updateTimeReport,
      timeReportId,
      timeReport
    );
  }
  if (result.data.status === "success") {
    timeReport = result.data.data;
    contract = timeReport.contract;
    const developer = contract.developer;
    yield put(actions.timeReportFormSetTimeReport(timeReport));
    yield put(actions.timeReportFormSetContract(contract));
    yield put(actions.timeReportFormSetDeveloper(developer));
  } else if (result.data.status === "error") {
    let errors = ["Unrecognized error"];
    if (result.data.data.errors !== undefined) {
      errors = result.data.data.errors;
    }
    yield put(actions.timeReportFormSetSendTimeReportErrors(errors));
  } else {
    throw new Error(
      "Unrecognized response from server on send time report attempt."
    );
  }
}

function* watchRetrieveTimeReport() {
  yield takeLatest(
    actionTypes.TIME_REPORT_FORM_RETRIEVE_TIME_REPORT,
    retrieveTimeReport
  );
}

function* retrieveTimeReport(action) {
  const result = yield call(dataFetcher.timeReports.getReport, [
    action.payload.id
  ]);
  if (result.data.status === "success") {
    const timeReport = result.data.data;
    const contract = timeReport.contract;
    const developer = contract.developer;
    yield put(actions.timeReportFormSetTimeReport(timeReport));
    yield put(actions.timeReportFormSetContract(contract));
    yield put(actions.timeReportFormSetDeveloper(developer));
  } else if (result.data.status === "error") {
    let errors = ["Unrecognized error"];
    if (result.data.data.errors !== undefined) {
      errors = result.data.data.errors;
    }
    yield put(actions.timeReportFormSetRetrieveTimeReportErrors(errors));
  } else {
    throw new Error(
      "Unrecognized response from server on retrieve time report attempt."
    );
  }
}

function* watchDeleteTimeReport() {
  yield takeLatest(
    actionTypes.TIME_REPORT_FORM_DELETE_TIME_REPORT,
    deleteTimeReport
  );
}

function* deleteTimeReport() {
  const timeReport = yield select(state => state.timeReportForm.timeReport);
  const result = yield call(dataFetcher.timeReports.deleteTimeReport, [
    timeReport.id
  ]);
  if (result.data.status === "success") {
    yield put(actions.timeReportFormResetTimeReport());
  } else if (result.data.status === "error") {
    let errors = ["Unrecognized error"];
    if (result.data.data.errors !== undefined) {
      errors = result.data.data.errors;
    }
    yield put(actions.timeReportFormSetRetrieveTimeReportErrors(errors));
  } else {
    throw new Error(
      "Unrecognized response from server on delete time report attempt."
    );
  }
}

function* watchContractFormRetrieveTogglWorkspaces() {
  yield takeLatest(
    CONTRACT_FORM_RETRIEVE_TOGGL_WORKSPACES,
    contractFormRetrieveTogglWorkspaces
  );
}

function* contractFormRetrieveTogglWorkspaces(action) {
  const developerId = action.payload;
  const response = yield call(dataFetcher.toggl.getWorkspaces, developerId);
  if (response.data.status === "success") {
    const workspaces = response.data.data.reduce(
      (acc, cur) => ({ ...acc, ...{ [cur.id]: cur.name } }),
      {}
    );
    yield put(contractFormSetTogglWorkspaces(workspaces));
    return;
  }
  if (response.data.status === "error") {
    /* 404 means the developer hasn't set their Toggl API key */
    if (response.status === 404) {
      yield put(contractFormSetNoTogglKey());
      return;
    }
  }
  throw new Error(
    "Unrecognized response from server on attempt to retrieve toggle workspaces."
  );
}

function* watchContractFormRetrieveTogglProjects() {
  yield takeLatest(
    CONTRACT_FORM_RETRIEVE_TOGGL_PROJECTS,
    contractFormRetrieveTogglProjects
  );
}

function* contractFormRetrieveTogglProjects(action) {
  const { developerId, workspaceId } = action.payload;
  const response = yield call(
    dataFetcher.toggl.getProjects,
    developerId,
    workspaceId
  );
  if (response.data.status === "success") {
    const projects = response.data.data.reduce(
      (acc, cur) => ({ ...acc, ...{ [cur.id]: cur.name } }),
      {}
    );
    yield put(actions.contractFormSetTogglProjects(projects));
    return;
  }
  if (response.data.status === "error") {
    // TODO error response handling
    return;
  }
  throw new Error(
    "Unrecognized response from server on attempt to retrieve toggle projects."
  );
}

function* watchRetrieveAccountManagers() {
  yield takeLatest(RETRIEVE_ACCOUNT_MANAGERS, retrieveAccountManagers);
}

function* retrieveAccountManagers(action) {
  const { data: response } = yield call(dataFetcher.accountManagers.index);
  if (response.status === "error") {
    // TODO error response handling
    return;
  }
  if (response.status === "success") {
    yield put(actions.setAccountManagers(response.data));
    return;
  }
  throw new Error(
    "Unrecognized response from server on attempt to retrieve account managers."
  );
}

function* watchRetrieveConfigContractCurrencies() {
  yield takeLatest(
    actionTypes.CONFIG_RETRIEVE_CONTRACT_CURRENCIES,
    retrieveConfigContractCurrencies
  );
}

function* retrieveConfigContractCurrencies(action) {
  const { data: response } = yield call(
    dataFetcher.config.getContractCurrencies
  );
  if (response.status === "success") {
    yield put(actions.configSetContractCurrencies(response.data));
    return;
  } else if (response.status === "error") {
    // @TODO error response handling
    return;
  }
  throw new Error(
    "Unrecognized response from server on attempt to get contract currencies config."
  );
}

function* watchRetrieveContractReports() {
  yield takeLatest(
    CONTRACT_REPORTS_RETRIEVE_CONTRACT_REPORTS,
    retrieveContractReports
  );
}

function* retrieveContractReports(action) {
  const params = yield select(state => state.contractReports.params);
  const requestParams = {
    start_date: params.startDate,
    end_date: params.endDate
  };
  if (params.accountManagerId !== 0) {
    requestParams.account_manager_id = params.accountManagerId;
  }

  const { data: response } = yield call(
    dataFetcher.timeReports.getContractReports,
    requestParams
  );
  if (response.status === "success") {
    const contractReports = response.data.map(contractReport => ({
      id: contractReport.id,
      name: contractReport.name,
      developerName: contractReport.developer.name,
      client: (contractReport.project && contractReport.project.client) || "",
      project: (contractReport.project && contractReport.project.name) || "",
      projectGroup:
        (contractReport.project &&
          contractReport.project.project_group &&
          contractReport.project.project_group.name) ||
        "",
      hours: contractReport.hours,
      hourlyRate: contractReport.hourly_rate,
      lastHoursConfirmationDate: contractReport.lastHoursConfirmationDate,
      position: contractReport.position
    }));
    yield put(actions.contractReportsSetContractReports(contractReports));
    return;
  } else if (response.status === "error") {
    return;
  }
  throw new Error(
    "Unrecognized response from server on attempt to get contract reports."
  );
}

function* watchGeneralRetrieveClientNames() {
  yield takeLatest(GENERAL_RETRIEVE_CLIENT_NAMES, generalRetrieveClientNames);
}

function* generalRetrieveClientNames() {
  const { data: response } = yield call(dataFetcher.general.getClientNames);
  if (response.status === "success") {
    yield put(actions.generalSetClientNames(response.data));
    return;
  } else if (response.status === "error") {
    // @TODO error response handling
    return;
  }
  throw new Error(
    "Unrecognized response from server on attempt to get client names."
  );
}

export default function* rootSaga() {
  yield all([
    watchRetrieveContracts(),
    watchRetrieveProjects(),
    watchRetrieveProjectGroups(),
    watchRetrieveInactiveContracts(),
    watchRetrieveCurrentUser(),
    watchRetrieveDevelopers(),
    watchContractFormRetrieveContract(),
    watchProjectFormRetrieveProject(),
    watchContractFormSendContract(),
    watchProjectFormSendProject(),
    watchRetrieveTimeReports(),
    watchSendTimeReport(),
    watchRetrieveTimeReport(),
    watchRetrieveDailyTimeReports(),
    watchDeleteTimeReport(),
    watchContractFormRetrieveTogglWorkspaces(),
    watchContractFormRetrieveTogglProjects(),
    watchRetrieveAccountManagers(),
    watchRetrieveConfigContractCurrencies(),
    watchRetrieveContractReports(),
    watchGeneralRetrieveClientNames()
  ]);
}
