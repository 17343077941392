import React, { ChangeEvent } from "react";
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  FormHelperText
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { contractFormSetContract } from "redux/actions";
import { ContractFormContract, ContractProvider } from "../../types/contract";
import { ContractFormErrors } from "../../redux/types/contractForm";

interface ProviderDropDownProps {
  contract: ContractFormContract;
  isBeingEdited: boolean;
  fieldsErrors: ContractFormErrors;
}

export default function ProviderDropDown({
  contract,
  isBeingEdited,
  fieldsErrors
}: ProviderDropDownProps) {
  const dispatch = useDispatch();

  const changeProvider = (
    event: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    dispatch(
      contractFormSetContract({
        ...contract,
        provider: event.target.value as ContractProvider
      })
    );
  };
  return (
    <>
      <FormControl
        disabled={!isBeingEdited}
        fullWidth
        component={"div"}
        error={fieldsErrors.provider !== undefined}
        required
      >
        <InputLabel htmlFor="type">Provider</InputLabel>
        <Select
          value={contract.provider}
          onChange={changeProvider}
          inputProps={{
            name: "provider",
            id: "provider",
            fullWidth: true
          }}
        >
          <MenuItem value={ContractProvider.Upwork}>Upwork</MenuItem>
          <MenuItem value={ContractProvider.Dotwrk}>Dotwrk</MenuItem>
          <MenuItem value={ContractProvider.Toptal}>Toptal</MenuItem>
          <MenuItem value={ContractProvider.Toggl}>Toggl</MenuItem>
        </Select>
        <FormHelperText>{fieldsErrors.provider || ""}</FormHelperText>
      </FormControl>
      {contract.provider === ContractProvider.Toggl && (
        <TogglOptions
          contract={contract}
          isBeingEdited={isBeingEdited}
          fieldsErrors={fieldsErrors}
        />
      )}
    </>
  );
}

function TogglOptions({
  contract,
  isBeingEdited,
  fieldsErrors
}: ProviderDropDownProps) {
  const dispatch = useDispatch();

  const hasTogglKey: boolean = useSelector(
    // @ts-ignore
    state => state.contractForm.hasTogglKey
  );
  const togglWorkspaces: { [key: number]: string } = useSelector(
    // @ts-ignore
    state => state.contractForm.togglWorkspaces
  );
  const toggleProjects: { [key: number]: string } = useSelector(
    // @ts-ignore
    state => state.contractForm.togglProjects
  );

  /* Set helper text to provider field if this developer has no Toggl API key */
  if (
    contract.provider === "toggl" &&
    !hasTogglKey &&
    fieldsErrors.provider === undefined
  ) {
    fieldsErrors.provider =
      "Cannot be selected because there is no Toggl API key in user profile on CRM or user does not have workspaces.";
  }

  return (
    <Container style={{ marginTop: "5px" }}>
      {hasTogglKey && (
        <Grid container justify="space-evenly" spacing={2}>
          <Grid item xs={6}>
            <FormControl
              disabled={!isBeingEdited}
              fullWidth
              required
              error={fieldsErrors.toggl_workspace_id !== undefined}
            >
              <InputLabel htmlFor="toggl_workspace_id">
                Toggl Workspace ID
              </InputLabel>
              <Select
                value={contract.toggl_workspace_id || -1}
                onChange={event =>
                  dispatch(
                    contractFormSetContract({
                      ...contract,
                      toggl_workspace_id: event.target.value as number
                    })
                  )
                }
                inputProps={{
                  name: "toggl_workspace_id",
                  id: "toggl_workspace_id",
                  fullWidth: true
                }}
              >
                {Object.entries(togglWorkspaces).map(([id, name]) => (
                  <MenuItem value={id}>{name}</MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {fieldsErrors.toggl_workspace_id || ""}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              disabled={!isBeingEdited}
              fullWidth
              required
              error={fieldsErrors.toggl_project_id !== undefined}
            >
              <InputLabel htmlFor="toggl_project_id">
                Toggl Project ID
              </InputLabel>
              <Select
                value={contract.toggl_project_id || -1}
                onChange={event =>
                  dispatch(
                    contractFormSetContract({
                      ...contract,
                      toggl_project_id: event.target.value as number
                    })
                  )
                }
                inputProps={{
                  name: "toggl_project_id",
                  id: "toggl_project_id",
                  fullWidth: true
                }}
              >
                {Object.entries(toggleProjects).map(([id, name]) => (
                  <MenuItem value={id}>{name}</MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {fieldsErrors.toggl_project_id || ""}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
