import {
  CONTRACT_FORM_RESET_CONTRACT,
  CONTRACT_FORM_RESET_RETRIEVE_CONTRACT_ERRORS,
  CONTRACT_FORM_RESET_SEND_CONTRACT_ERRORS,
  CONTRACT_FORM_RETRIEVE_CONTRACT,
  CONTRACT_FORM_SEND_CONTRACT,
  CONTRACT_FORM_SET_CONTRACT,
  CONTRACT_FORM_SET_NO_TOGGL_KEY,
  CONTRACT_FORM_SET_RETRIEVE_CONTRACT_ERRORS,
  CONTRACT_FORM_SET_SEND_CONTRACT_ERRORS,
  CONTRACT_FORM_SET_TOGGL_PROJECTS,
  CONTRACT_FORM_SET_TOGGL_WORKSPACES,
  ContractFormActionTypes,
  ContractFormState
} from "../types/contractForm";
import moment from "moment";
import { DATETIME_FORMAT } from "../../variables/general";
import {
  ContractPosition,
  ContractProvider,
  ContractStatus,
  ContractType
} from "../../types/contract";
import { isCorrectPositiveDecimal } from "../../helpers";

const initialState: ContractFormState = {
  data: {
    id: 0,
    name: "",
    developer_id: null,
    project_id: null,
    time_intervals: [
      { id: 0, start_date: moment().format(DATETIME_FORMAT), end_date: null }
    ],
    status: ContractStatus.Active,
    type: ContractType.Hourly,
    hourly_rate: "",
    provider: ContractProvider.Dotwrk,
    contract_amount: "",
    daily_workload: "",
    toggl_workspace_id: null,
    toggl_project_id: null,
    currency_code: "",
    position: ContractPosition.SOFTWARE_ENGINEER,
  },
  fetching: false,
  sending: false,
  retrieveErrors: null,
  sendErrors: null,
  togglWorkspaces: {},
  togglProjects: {},
  hasTogglKey: true
};

export default function(
  state: ContractFormState = initialState,
  action: ContractFormActionTypes
): ContractFormState {
  switch (action.type) {
    case CONTRACT_FORM_SET_CONTRACT:
      if (!isCorrectPositiveDecimal(action.payload.daily_workload)) {
        action.payload.daily_workload = state.data.daily_workload;
      }
      if (!isCorrectPositiveDecimal(action.payload.contract_amount)) {
        action.payload.contract_amount = state.data.contract_amount;
      }
      if (!isCorrectPositiveDecimal(action.payload.hourly_rate)) {
        action.payload.hourly_rate = state.data.hourly_rate;
      }
      return {
        ...state,
        data: action.payload,
        fetching: false,
        sending: false
      };
    case CONTRACT_FORM_RETRIEVE_CONTRACT:
      return { ...state, fetching: true, retrieveErrors: null };
    case CONTRACT_FORM_SEND_CONTRACT:
      return { ...state, sending: true, sendErrors: null };
    case CONTRACT_FORM_SET_RETRIEVE_CONTRACT_ERRORS:
      return {
        ...state,
        retrieveErrors: Array.isArray(action.payload)
          ? [...action.payload]
          : { ...action.payload },
        sending: false,
        fetching: false
      };
    case CONTRACT_FORM_SET_SEND_CONTRACT_ERRORS:
      return {
        ...state,
        sendErrors: Array.isArray(action.payload)
          ? [...action.payload]
          : { ...action.payload },
        sending: false,
        fetching: false
      };
    case CONTRACT_FORM_RESET_CONTRACT:
      return { ...state, data: { ...initialState.data } };
    case CONTRACT_FORM_RESET_RETRIEVE_CONTRACT_ERRORS:
      return { ...state, retrieveErrors: null };
    case CONTRACT_FORM_RESET_SEND_CONTRACT_ERRORS:
      return { ...state, sendErrors: null };
    case CONTRACT_FORM_SET_TOGGL_WORKSPACES:
      return { ...state, togglWorkspaces: action.payload, hasTogglKey: true };
    case CONTRACT_FORM_SET_TOGGL_PROJECTS:
      return { ...state, togglProjects: action.payload, hasTogglKey: true };
    case CONTRACT_FORM_SET_NO_TOGGL_KEY:
      return {
        ...state,
        togglWorkspaces: {},
        togglProjects: {},
        hasTogglKey: false
      };
    default:
      return state;
  }
}
