import axios from "axios";

const fetcher = axios.create({
  baseURL: `${process.env.REACT_APP_PATH}/api`,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`
  },
  validateStatus: status => {
    return status >= 200 && status < 500;
  }
});

fetcher.interceptors.response.use(
  response => {
    if (response.status === 401) {
      // That means we have auth problems. Remove token and reload page. Routing should take care about further logic.
      localStorage.removeItem("token");
      localStorage.removeItem("modal-was-shown");
      window.location.reload();
    }
    return response;
  }
  // error => {
  //   return Promise.reject(error);
  // }
);

export default {
  timeReports: {
    getReports: params => {
      return fetcher.get("time-reports", {
        params: { ...params }
      });
    },
    getReport: id => {
      return fetcher.get(`time-reports/${id}`);
    },
    getDailyReports: params => {
      return fetcher.get("time-reports/daily", {
        params: { ...params }
      });
    },
    getContractReports: params => {
      return fetcher.get("time-reports/contract-stats", {
        params: { ...params }
      });
    },
    getDevelopersStat: params => {
      return fetcher.get("time-reports/developers-stat", {
        params: { ...params }
      });
    },
    getDepartmentsStat: params => {
      return fetcher.get("time-reports/departments-stat", {
        params: { ...params }
      });
    },
    createTimeReport: data => {
      return fetcher.post("time-reports", { ...data });
    },
    updateTimeReport: (id, data) => {
      return fetcher.put(`time-reports/${id}`, data);
    },
    deleteTimeReports: data => {
      return fetcher.post(`time-reports/mass-delete`, data);
    },
    restoreTimeReports: data => {
      return fetcher.post(`time-reports/restore-reports`, data);
    },
    deleteTimeReport: id => {
      return fetcher.delete(`time-reports/${id}`);
    }
  },
  projects: {
    getProjects: params => {
      return fetcher.get("projects", {
        params: { ...params }
      });
    },
    getProject: id => {
      return fetcher.get(`projects/${id}`);
    },
    createProject: data => {
      return fetcher.post("projects", data);
    },
    updateProject: (id, data) => {
      return fetcher.put(`projects/${id}`, data);
    }
  },
  projectGroups: {
    getProjectGroups: () => {
      return fetcher.get("project-groups");
    },
    getProjectGroup: id => {
      return fetcher.get(`project-groups/${id}`);
    },
    createProjectGroup: data => {
      return fetcher.post("project-groups", data);
    },
    updateProjectGroup: (id, data) => {
      return fetcher.put(`project-groups/${id}`, data);
    },
    deleteProjectGroup: (id) => {
      return fetcher.delete(`project-groups/${id}`);
    }
  },
  contracts: {
    getContracts: params => {
      return fetcher.get("contracts", {
        params: { ...params }
      });
    },
    getContract: id => {
      return fetcher.get(`contracts/${id}`);
    },
    createContract: data => {
      return fetcher.post("contracts", data);
    },
    updateContract: (id, data) => {
      return fetcher.put(`contracts/${id}`, data);
    },
    getInactiveContracts: params => {
      return fetcher.get("inactive-contracts", {
        params: { ...params }
      });
    }
  },
  developers: {
    getActiveDevelopers: () => {
      return fetcher.get("user/developers");
    }
  },
  user: {
    login: data => {
      return fetcher.post("user/login", data);
    },
    get: id => {
      return fetcher.get("user/show" + (id ? "/" + id : ""));
    }
  },
  accountManagers: {
    index: () => {
      return fetcher.get("user/account-managers");
    }
  },
  toggl: {
    getWorkspaces: devID => {
      return fetcher.get("toggl-workspaces", {
        params: {
          developer_id: devID
        }
      });
    },
    getProjects: (devID, workspaceID) => {
      return fetcher.get("toggl-projects", {
        params: {
          developer_id: devID,
          workspace_id: workspaceID
        }
      });
    }
  },
  config: {
    getContractCurrencies: () => {
      return fetcher.get("config/contract-currencies");
    }
  },
  general: {
    getClientNames: () => {
      return fetcher.get("crm/client-names");
    }
  }
};
