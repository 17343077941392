import { ContractFormContract } from "../../types/contract";

export const CONTRACT_FORM_RETRIEVE_CONTRACT =
  "CONTRACT_FORM_RETRIEVE_CONTRACT";
export const CONTRACT_FORM_SET_CONTRACT = "CONTRACT_FORM_SET_CONTRACT";
export const CONTRACT_FORM_SEND_CONTRACT = "CONTRACT_FORM_SEND_CONTRACT";
export const CONTRACT_FORM_SET_RETRIEVE_CONTRACT_ERRORS =
  "CONTRACT_FORM_SET_RETRIEVE_CONTRACT_ERRORS";
export const CONTRACT_FORM_SET_SEND_CONTRACT_ERRORS =
  "CONTRACT_FORM_SET_SEND_CONTRACT_ERRORS";
export const CONTRACT_FORM_RESET_CONTRACT = "CONTRACT_FORM_RESET_CONTRACT";
export const CONTRACT_FORM_RESET_RETRIEVE_CONTRACT_ERRORS =
  "CONTRACT_FORM_RESET_RETRIEVE_CONTRACT_ERRORS";
export const CONTRACT_FORM_RESET_SEND_CONTRACT_ERRORS =
  "CONTRACT_FORM_RESET_SEND_CONTRACT_ERRORS";
export const CONTRACT_FORM_RETRIEVE_TOGGL_WORKSPACES =
  "CONTRACT_FORM_TOGGL_RETRIEVE_WORKSPACES";
export const CONTRACT_FORM_RETRIEVE_TOGGL_PROJECTS =
  "CONTRACT_FORM_TOGGL_RETRIEVE_PROJECTS";
export const CONTRACT_FORM_SET_TOGGL_WORKSPACES =
  "CONTRACT_FORM_TOGGL_SET_WORKSPACES";
export const CONTRACT_FORM_SET_TOGGL_PROJECTS =
  "CONTRACT_FORM_TOGGL_SET_PROJECTS";
export const CONTRACT_FORM_SET_NO_TOGGL_KEY = "CONTRACT_FORM_TOGGL_SET_NO_KEY";

type ContractFormRetrieveContractAction = {
  type: typeof CONTRACT_FORM_RETRIEVE_CONTRACT;
  payload: number;
};

type ContractFormSetContractAction = {
  type: typeof CONTRACT_FORM_SET_CONTRACT;
  payload: ContractFormContract;
};

type ContractFormSendContractAction = {
  type: typeof CONTRACT_FORM_SEND_CONTRACT;
  payload: ContractFormContract;
};

type ContractFormSetRetrieveContractErrorsAction = {
  type: typeof CONTRACT_FORM_SET_RETRIEVE_CONTRACT_ERRORS;
  payload: Errors;
};

type ContractFormSetSendContractErrorsAction = {
  type: typeof CONTRACT_FORM_SET_SEND_CONTRACT_ERRORS;
  payload: Errors;
};

type ContractFormResetContractAction = {
  type: typeof CONTRACT_FORM_RESET_CONTRACT;
};

type ContractFormResetRetrieveContractErrorsAction = {
  type: typeof CONTRACT_FORM_RESET_RETRIEVE_CONTRACT_ERRORS;
};

type ContractFormResetSendContractErrorsAction = {
  type: typeof CONTRACT_FORM_RESET_SEND_CONTRACT_ERRORS;
};

type ContractFormSetTogglWorkspacesAction = {
  type: typeof CONTRACT_FORM_SET_TOGGL_WORKSPACES;
  payload: { [key: number]: string };
};

type ContractFormSetTogglProjectsAction = {
  type: typeof CONTRACT_FORM_SET_TOGGL_PROJECTS;
  payload: { [key: number]: string };
};

type ContractFormRetrieveTogglWorkspacesAction = {
  type: typeof CONTRACT_FORM_RETRIEVE_TOGGL_WORKSPACES;
  payload: number;
};

type ContractFormRetrieveTogglProjectsAction = {
  type: typeof CONTRACT_FORM_RETRIEVE_TOGGL_PROJECTS;
  payload: {
    developerId: number;
    workspaceId: number;
  };
};

type ContractFormSetNoTogglKeyAction = {
  type: typeof CONTRACT_FORM_SET_NO_TOGGL_KEY;
};

export type ContractFormActionTypes =
  | ContractFormRetrieveContractAction
  | ContractFormSetContractAction
  | ContractFormSendContractAction
  | ContractFormSetRetrieveContractErrorsAction
  | ContractFormSetSendContractErrorsAction
  | ContractFormResetContractAction
  | ContractFormResetRetrieveContractErrorsAction
  | ContractFormResetSendContractErrorsAction
  | ContractFormSetTogglWorkspacesAction
  | ContractFormSetTogglProjectsAction
  | ContractFormRetrieveTogglWorkspacesAction
  | ContractFormRetrieveTogglProjectsAction
  | ContractFormSetNoTogglKeyAction;

export type ContractFormErrors = { [k in keyof ContractFormContract]?: string };
export type Errors = string[] | ContractFormErrors;

export type ContractFormState = {
  data: ContractFormContract;
  fetching: boolean;
  sending: boolean;
  retrieveErrors: Errors | null;
  sendErrors: Errors | null;
  togglWorkspaces: { [key: number]: string };
  togglProjects: { [key: number]: string };
  hasTogglKey: boolean;
};
